import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

import fr from "vuetify/lib/locale/fr";

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.red.darken4,
        secondary: colors.red.lighten3,
        accent: colors.indigo.base
      },
      dark: {
        primary: colors.red.darken4,
        secondary: colors.red.lighten3,
        accent: colors.indigo.base
      }
    }
  },
  lang: {
    locales: { fr },
    current: "fr"
  }
});
